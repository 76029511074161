import { usePiPWindow } from 'components/PiP/PiPProvider';
import { SourceDetails } from 'features/layout/types';
import { IconButtonProps, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MicControlRemote } from 'features/streaming/components/controls/MicControlRemote';
import { MicControlLocal } from 'features/streaming/components/controls/MicControlLocal';
import { TileControls } from 'features/streaming/components/TileControls';
import React from 'react';

interface StreamControlsProps {
  source: SourceDetails;
  collapseButtons: boolean;
}

export type StreamControlProps = IconButtonProps<
  'button',
  { source: SourceDetails; onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void }
>;

const Root = styled('div')({
  position: 'absolute',
  zIndex: 1,
  bottom: '8px',
  left: '8px',
  display: 'flex',
});

export const TileControlButtons = React.memo(({ source, collapseButtons }: StreamControlsProps) => {
  const local = source.kind === 'local';

  const renderMicControl = () => {
    if (source.kind === 'screenshare-local' || source.kind === 'screenshare-remote') {
      return null;
    }

    return local ? (
      <MicControlLocal userId={source.userId} />
    ) : (
      <MicControlRemote userId={source.userId} />
    );
  };

  const collapse = collapseButtons && (source.kind === 'local' || source.kind === 'remote');

  const { pipWindow } = usePiPWindow();

  return (
    <Root>
      <Stack direction="row" spacing={2}>
        {renderMicControl()}
        {pipWindow ? null : <TileControls source={source} collapse={collapse} />}
      </Stack>
    </Root>
  );
});
